.loginFlow-recaptcha-container {
  width: 100%;
  height: "71px";
}
 
.g-recaptcha {
  transform: scaleX(1.3);
  transform-origin: 0 0;
  width: 100%;
  overflow: hidden;
  border: none; /* Remove border from the reCAPTCHA element */
  
}
 
#rc-imageselect {
  transform: scaleX(1.3);
  transform-origin: 0 0;
}
 
/* Remove the border of specific reCAPTCHA classes */
.rc-anchor-light.rc-anchor-normal,
.rc-anchor-light.rc-anchor-compact {
   border: none !important; /* Remove border */
}
 
@media screen and (max-height: 575px) {
  #rc-imageselect,
  .g-recaptcha {
    transform: scaleX(1.3);
    transform-origin: 0 0;
  }
}
 
@media only screen and (max-width: 500px) {
  .g-recaptcha {
    transform: scaleX(1.3);
    transform-origin: 0 0;
  }
}