input::-ms-reveal,
input::-ms-clear {
    display: none;
}

.hexagons-container {
    position: fixed;
    top: 0;
    right: -10vh;
    z-index: -1;
    height: 100vh;
    width: 100vw;
}

.lines {
    background-image: url("../public/assets/login-bg/lines.png");
    background-position: bottom right;
    background-size: cover;
    background-repeat: no-repeat;
    position: absolute;
    height: 500px;
    width: 400px;
}

.lines1 {
    top: -175px;
    left: -125px;
}

.lines2 {
    bottom: -300px;
    right: calc(50vh + 265px);
}

.hexagon {
    overflow: hidden;
    visibility: hidden;
    -webkit-transform: rotate(120deg);
    -moz-transform: rotate(120deg);
    -ms-transform: rotate(120deg);
    -o-transform: rotate(120deg);
    transform: rotate(120deg);
    cursor: pointer;
    position: absolute;
    width: 100vh;
    height: 50vh;
}

.hexagon-in1 {
    overflow: hidden;
    width: 100%;
    height: 100%;
    -webkit-transform: rotate(-60deg);
    -moz-transform: rotate(-60deg);
    -ms-transform: rotate(-60deg);
    -o-transform: rotate(-60deg);
    transform: rotate(-60deg);
}

.hexagon-in2 {
    width: 100%;
    height: 100%;
    background-repeat: no-repeat;
    background-position: 50%;
    visibility: visible;
    -webkit-transform: rotate(-60deg);
    -moz-transform: rotate(-60deg);
    -ms-transform: rotate(-60deg);
    -o-transform: rotate(-60deg);
    transform: rotate(-60deg);
}

.hexagon1 {
    top: -8px;
    right: 25vh;
}

.hexagon1 .hexagon-in2 {
    background-image: url("../public/assets/login-bg/img-2.png");
    background-size: contain;
}

.hexagon2 {
    top: 25vh;
    right: calc(-18vh - 16px);
}

.hexagon2 .hexagon-in2 {
    background-image: url("../public/assets/login-bg/img-1.png");
    background-size: contain;
    background-color: #a29491;
}

.hexagon3 {
    top: calc(50vh + 8px);
    right: 25vh;
}

.hexagon3 .hexagon-in2 {
    background-image: url("../public/assets/login-bg/img-3.png");
    background-size: contain;
}

.hexagon4 {
    top: calc(-25vh - 16px);
    right: calc(-18vh - 16px);
}

.hexagon4 .hexagon-in2 {
    background: #F15D27;
    opacity: 0.05;
    background-size: contain;
}

.hexagon5 {
    top: calc(75vh + 16px);
    right: calc(-18vh - 16px);
}

.hexagon5 .hexagon-in2 {
    background: #F15D27;
    opacity: 0.05;
    background-size: contain;
}


.swal2-container.swal2-center.swal2-backdrop-show {
    z-index: 10600;
}

.clock-container {
    position: fixed;
    z-index: 9999999;
    height: 100vh;
    width: 100vw;
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 100vh;
    margin: 0;
    background: rgba(0, 0, 0, 0.7);
}

.clock-loader {
    --clock-color: #F15D27;
    --clock-width: 4rem;
    --clock-radius: calc(var(--clock-width) / 2);
    --clock-minute-length: calc(var(--clock-width) * 0.4);
    --clock-hour-length: calc(var(--clock-width) * 0.2);
    --clock-thickness: 0.3rem;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    width: var(--clock-width);
    height: var(--clock-width);
    border: 3px solid var(--clock-color);
    border-radius: 50%;
}

.clock-loader::before,
.clock-loader::after {
    position: absolute;
    content: "";
    top: calc(var(--clock-radius) * 0.25);
    width: var(--clock-thickness);
    background: var(--clock-color);
    border-radius: 10px;
    transform-origin: center calc(100% - calc(var(--clock-thickness) / 2));
    animation: spin infinite linear;
}

.clock-loader::before {
    height: var(--clock-minute-length);
    animation-duration: 2s;
}

.clock-loader::after {
    top: calc(var(--clock-radius) * 0.25 + var(--clock-hour-length));
    height: var(--clock-hour-length);
    animation-duration: 15s;
}

@keyframes spin {
    to {
        transform: rotate(1turn);
    }
}

[id$="couponCodePrefix-input"],
[id$="pan-input"],
[id$="pan2-input"] {
    text-transform: uppercase;
}

.profile .MuiPaper-root.MuiPaper-outlined.MuiPaper-rounded.MuiCard-root {
    box-shadow: none !important;
    border: none !important;
}


.profile .MuiPaper-root.MuiPaper-outlined.MuiPaper-rounded.MuiCard-root .MuiCardHeader-root {
    padding: 0 !important;
}

.profile .MuiPaper-root.MuiPaper-outlined.MuiPaper-rounded.MuiCard-root .MuiCardContent-root {
    padding: 0 !important;
}

strike {
    text-decoration: none;
    position: relative;
}

strike:after {
    content: ' ';
    position: absolute;
    width: 100%;
    height: 2px;
    background: red;
    transform: translateY(15px) rotate(45deg);
    top: 0;
    left: 0;
}

strike:before {
    content: ' ';
    position: absolute;
    width: 100%;
    height: 2px;
    background: red;
    transform: translateY(15px) rotate(-45deg);
    top: 0;
    left: 0;
}



*::-webkit-scrollbar {
    width: 8px;
    height: 8px;
    background-color: #C4CDD5;
    /* or add it to the track */
}

*::-webkit-scrollbar-thumb {
    background: #FFA25C;
    border-radius: 4px;
}

ol>li:before,
ul>li:before {
    content: "" !important;
}